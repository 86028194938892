import React, {useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import {CardsSuit, Prediction, PlayerOddType, BetsType} from "@constants/Enums";

import RoundInfoItem from "./RoundInfoItem";
import CardList from "./CardList";
import {floatNumberWithSpaces} from "@services/numberFormatServices";

export function RoundHistory({round, getHistoryItemValue}) {
    const [playerACards, setPlayerACards] = useState([])
    const [playerBCards, setPlayerBCards] = useState([])

    useEffect(() => {
        if (round.cards) {
            let cardsA = [], cardsB = [];
            round.cards.forEach((card, index) => {
                const cardItem = card.split(",")
                const cardString =  cardItem[0] + CardsSuit[cardItem[1]]
                if (index % 2 !==0) {
                    cardsB.push(cardString)
                } else {
                    cardsA.push(cardString)
                }
            })
            setPlayerBCards(cardsB)
            setPlayerACards(cardsA)
        }
    },[round])

    return (
        <div className="history-round scrollable">
            <div className="history-round-info">
                <RoundInfoItem time={round.startTime}
                               timeTitle="game_start"
                               balance={round.balanceBefore}
                               balanceTitle="balance_before_the_round" />
                <RoundInfoItem time={round.endTime}
                               timeTitle="game_end"
                               balance={round.balanceAfter}
                               balanceTitle="balance_after_the_round" />
            </div>
            <div className="round-cards">
                <CardList player={<FormattedMessage id="player_a" />}
                          cards={playerACards}
                          winner={round.won === PlayerOddType.PlayerA || round.won === PlayerOddType.Both}/>

                <CardList player={<FormattedMessage id="player_b" />}
                          cards={playerBCards}
                          winner={round.won === PlayerOddType.PlayerB || round.won === PlayerOddType.Both}/>
            </div>
            <div className="round-bets">
                <div className="tbl">
                    <div className="row">
                        <div className="cell"><FormattedMessage id="bet_type" /></div>
                        <div className="cell"><FormattedMessage id="bet" /></div>
                        <div className="cell"><FormattedMessage id="odd" /></div>
                        <div className="cell"><FormattedMessage id="win_lose" /></div>
                    </div>
                    {
                        round.bets.map((item, index) => (
                            <div className="row" key={index}>
                                <div className="cell"><FormattedMessage id={Prediction[item.prediction]} /></div>
                                <div className="cell">{floatNumberWithSpaces(item.stake)}</div>
                                <div className="cell">{floatNumberWithSpaces(item.odd)}</div>
                                
                                <div className="cell">{floatNumberWithSpaces(getHistoryItemValue(item), 0)}</div>
                            </div>
                        ))
                    }
                    <div className="row">
                        <div className="cell"><FormattedMessage id="total" />:</div>
                        <div className="cell">{floatNumberWithSpaces(round.bets.reduce((a, b) => b.stake + a, 0))}</div>
                        <div className="cell"></div>
                        <div className="cell">{floatNumberWithSpaces(round.bets.reduce((a, b) => getHistoryItemValue(b) + a, 0), 0)}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default RoundHistory;