import React, {useEffect, useState, useContext} from "react";
import {connect} from "react-redux";
import {subscribeOdds} from "@actions/oddsAction";
import {selectBoard, resetSelectBoard} from "@actions/betsAction"
import LargeBoard from "@components/betBoard/largeBoard";
import SmallBoard from "@components/betBoard/smallBoard";
import {subscribeTickerWorker, unSubscribeTickerWorker} from "@actions/workerActions";
import Load from "@components/betBoard/load";
import {FormattedMessage} from "react-intl";
import classNames from "classnames";
import {ResizeContext} from "../../contexts/resizeContext";


function Board({subscribeOdds, odds, enabledBetAreas, subscribeTickerWorker, unSubscribeTickerWorker, selectBoard, threeD, selectedBoard, resetSelectBoard, loadInfo}) {
    const [disabledAll, setDisabledAll] = useState(false);
    const {mobile} = useContext(ResizeContext);

    useEffect(() => {
        subscribeOdds();
        function getGameTimeDiff(e) {
            const  timer = JSON.parse(e.data)
            if (!(timer.data && parseInt(timer.data / 1000) > 3)) {
                setDisabledAll(true)
            } else  {
                setDisabledAll(false)
            }
        }
        subscribeTickerWorker(getGameTimeDiff);
        return () => {
            unSubscribeTickerWorker(getGameTimeDiff)
        }
    }, [])

    useEffect(() => {
        if (disabledAll) {
            resetSelectBoard()
        }
    }, [disabledAll])

    return (
        <div className="board">
            <div className={classNames("board-info", {threeD})}>
                <div className="board-info-sides">
                    {/* <div className="title"><FormattedMessage id="player_a"/></div> */}
                    {/* <div className="title"><FormattedMessage id="player_b"/></div> */}
                </div>
                {
                    mobile ? <SmallBoard enabledBetAreas={enabledBetAreas} disabledAll={disabledAll} odds={odds} selectBoard={selectBoard} selectedBoard={selectedBoard} />
                    : <LargeBoard enabledBetAreas={enabledBetAreas} disabledAll={disabledAll} odds={odds} selectBoard={selectBoard} selectedBoard={selectedBoard} />

                }
                <div className="book-show-bottom">
                    <div className={`${loadInfo['prediction-2'] > 0 ? 'playera-pair-plus-book green' : ''}`}>{loadInfo['prediction-2'] > 0 ? loadInfo['prediction-2'] : ''}</div>
                    <div className={`${loadInfo['prediction-5'] > 0 ? 'playerb-pair-plus-book green' : ''}`}>{loadInfo['prediction-5'] > 0 ? loadInfo['prediction-5'] : ''}</div>
                </div>
            </div>
            <Load threeD={threeD} />
        </div>
      )
}

function mapStateToProps(state) {
    return {
        odds: state.odds,
        enabledBetAreas: state.enabledBetAreas,
        selectedBoard: state.selectedBoard,
        loadInfo: state.loadInfo
    }
}

const mapDispatchToProps = {
    subscribeOdds,
    unSubscribeTickerWorker,
    subscribeTickerWorker,
    selectBoard,
    resetSelectBoard
}

export default connect(mapStateToProps,mapDispatchToProps)(Board)