import React from "react";
import {WarningIcon} from "@resource";

function ConnectMessage({title, description, button}) {
    return (<div className="connect-message">
        <div className="icon-box">
            <WarningIcon />
        </div>

        <div className="info">
            <div className="title">{title}</div>
            <div className="description">{description}</div>
            {
                button.active ?  <button className="btn_1" onClick={button.callBack}>{button.text}</button> : ""
            }

        </div>
    </div>)
}

export default ConnectMessage